<template>
  
    <van-nav-bar fixed placeholder title="收货单" @click-left="onClickLeft" @click-right="onClickRight">
      <template #left>
        <van-icon v-if="state.user && state.user.status == 2" name="search" size="22" color="#999" />
      </template>
      <template #title>
        <h3>收货单</h3>
        <p v-if="state.user">
          {{ state.user.personName }} {{ state.user.mobile }}
        </p>
      </template>
      <template #right><van-button type="default" size="small">退出</van-button></template>
    </van-nav-bar>
  <div v-if="state.user && state.user.status == 2"> 
    <van-tabs
      v-model:active="activeNum"
      :before-change="beforeChange"
      sticky
      animated
      color="#FC6621">
      <van-tab title="全部">
        <template #title>全部</template>

        <van-pull-refresh v-model="state.refreshing" @refresh="onRefresh" success-text="刷新成功">
          <van-list
            v-model:loading="state.loading"
            :finished="state.finished"
            :finished-text="cpdList.length == 0 ? '' : '没有更多了'"
            :immediate-check="false"
             @load="onLoad(0)"
          >
            <home-item
              v-for="(item, index) in cpdList"
              :key="index"
              :item="item"
            />
          </van-list>
        </van-pull-refresh>

        <empty v-if="!state.loading && cpdList.length == 0" title="暂无数据" />
      </van-tab>

      <van-tab title="待签收：1">
        <template #title>待签收<span v-if="state.unSignNum > 0" class="pos-asb"></span></template>
        <van-pull-refresh v-model="state.refreshing" @refresh="onRefresh" success-text="刷新成功">
          <van-list
            v-model:loading="state.loading"
            :finished="state.finished"
            :finished-text="cpdList.length == 0 ? '' : '没有更多了'"
            :immediate-check="false"
             @load="onLoad(1)"
          >
            <home-item
              v-for="(item, index) in cpdList"
              :key="index"
              :item="item"
            />
          </van-list>
        </van-pull-refresh>
        <empty v-if="!state.loading && cpdList.length == 0" title="暂无数据" />
      </van-tab>

      <van-tab title="签收中: 0">
        <template #title>签收中<span v-if="state.signingNum > 0" class="pos-asb"></span></template>
        <van-pull-refresh v-model="state.refreshing" @refresh="onRefresh" success-text="刷新成功">
          <van-list
            v-model:loading="state.loading"
            :finished="state.finished"
            :finished-text="cpdList.length == 0 ? '' : '没有更多了'"
            :immediate-check="false"
            @load="onLoad(2)"
          >
            <home-item
              v-for="(item, index) in cpdList"
              :key="index"
              :item="item"
            />
          </van-list>
        </van-pull-refresh>
        <empty v-if="!state.loading && cpdList.length == 0" title="暂无数据" />
      </van-tab>


      <van-tab title="已签收：3">
        <template #title>已签收</template>
        <van-pull-refresh v-model="state.refreshing" @refresh="onRefresh" success-text="刷新成功">
          <van-list
            v-model:loading="state.loading"
            :finished="state.finished"
            :finished-text="cpdList.length == 0 ? '' : '没有更多了'"
            :immediate-check="false"
            @load="onLoad(3)"
          >
            <home-item
              v-for="(item, index) in cpdList"
              :key="index"
              :item="item"
            />
          </van-list>
        </van-pull-refresh>
        <empty v-if="!state.loading && cpdList.length == 0" title="暂无数据" />
      </van-tab>


      <van-tab title="已拒签：2">
        <template #title>已拒签</template>
        <van-pull-refresh v-model="state.refreshing" @refresh="onRefresh" success-text="刷新成功">
          <van-list
            v-model:loading="state.loading"
            :finished="state.finished"
            :finished-text="cpdList.length == 0 ? '' : '没有更多了'"
            :immediate-check="false"
            @load="onLoad(4)"
          >
            <home-item
              v-for="(item, index) in cpdList"
              :key="index"
              :item="item"
            />
          </van-list>
        </van-pull-refresh>
        <empty v-if="!state.loading && cpdList.length == 0" title="暂无数据" />
      </van-tab>
    </van-tabs>
  </div>
  <div class="click-refuse" v-else>
    <van-button size="small" type="primary" round style="width:80%;" @click="getProfile">刷新认证状态</van-button>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { reactive, computed } from "vue";
import { deliveryNotices, profile } from "@/api/home";
import { getLSt, setLSt } from "@/utils/utis";
import homeItem from "@/components/home-item";
import empty from "@/components/empty";
import { useRouter } from "vue-router";
import { useStore } from 'vuex'
export default {
  components: {
    homeItem,
    empty
  },
  setup() {
    const routers = useRouter();
    const store = useStore()
    const state = reactive({
      loading: false,
      finished: false,
      refreshing: false,
      user: null,
      unSignNum: 0,
      signingNum: 0
    });

    const cpdList = computed(() => {
      return store.state.dataList
    })

    const activeNum = computed(() => {
      return store.state.activeNum
    })

    const onLoad = async (i) => {
      if(store.state.activeNum != i) return
      if (state.refreshing) {
        store.commit('mutList', [])
        state.refreshing = false;
      }
      const list = store.state.dataList
      const vl = list.length == 0 ? 1 : store.state.spage + 1
      store.commit('mutSpage', vl)

      const { dataList, signingNum, unSignNum, page, totalPage, recode } = await deliveryNotices({
        page: vl,
        size: 10,
        signStatus: i == 0 ? "" :  i == 1 ? 1 : i == 2 ? 0 : i == 3 ? 3 : i == 4 ? 2 : ""
      });
      
      state.signingNum = signingNum || 0
      state.unSignNum = unSignNum || 0

      if(recode != 200) return

      if(vl > 1) {
        store.commit('mutList', list.concat(dataList))
      } else {
        store.commit('mutList', dataList)
      }

      state.loading = false;
      if (page >= totalPage ) {
        state.finished = true;
      }
    };


    // 下拉刷新
    const onRefresh = () => {
      store.commit('mutList', [])
      store.commit('mutSpage', 1)
      state.finished = false;
      state.loading = true;
      onLoad(store.state.activeNum);
    };

    // 认证弹窗
    const onAlert = (obj) => {
      Dialog.confirm({
        title: obj.title,
        confirmButtonText: obj.confirmButtonText,
        confirmButtonColor: "#FC6621",
        cancelButtonText: '退出登录',
        allowHtml: true,
        message: obj.message,
      }).then(() => {
        location.href = state.user.url;
      }).catch(() => {
        localStorage.clear()
        routers.push({ path: '/login' })
      });
    };
    // tab切换
    const beforeChange = (i) => {
      state.finished = false;
      state.loading = true;
      store.commit('mutList', [])
      store.commit('mutSpage', 1)
      store.commit('upNum', i)
      // 签收中：0，待签收：1，已拒签：2，已签收：3
      onLoad(i);
      return true;
    };

    // 查询认证状态
    const getProfile = async () => {
      const user = await getLSt("userinfo");
      if(!user){
        routers.push({ path: '/login' })
        return
      }
      const users = JSON.parse(user);
      state.user = users;
      
      const st = await profile();
      if(st.recode != 200) return
      st.token = users.token
      state.user = users;
      // st.status = 2
      setLSt("userinfo", JSON.stringify(st));
      //  0:未激活; 1:未认证; 2:审核通过; 3:已提交待审核; 4:审核不通过
      const mb = `<span style="color:#FC6621">${state.user.mobile}</span>`
      if (st.status == 0 || st.status == 1) {
        onAlert({
          title: '提示',
          confirmButtonText: '实名认证',
          message: `需用${mb}该手机用户身份先实名认证后才能签收收货单,签收后具有相关法律效力，请认真查阅相关认证条款。`
        });
      }else if (st.status == 3){
        onAlert({
          title: '提示',
          confirmButtonText: '刷新状态',
          message: `${mb}该手机用户已提交认证资料！目前为身份审核中状态。`
        });
      } else if (st.status == 4){
        onAlert({
          title: '认证不通过',
          confirmButtonText: '实名认证',
          message: `${mb}该手机用户认证不通过！请重新实名认证。`
        });
      } else {
        onLoad(store.state.activeNum);
      }
    };

    getProfile();

    const onClickLeft = () => {
      if(state.user.status != 2) return
      routers.push({ path: '/search' })
    };
    const onClickRight = () => {
      Dialog.confirm({
        title: '提示',
        confirmButtonText: '退出',
        confirmButtonColor: "#FC6621",
        allowHtml: true,
        message: `确定退出<span style="color:#FC6621">${state.user.mobile}</span>该手机账号？`,
      }).then(() => {
        localStorage.clear()
        routers.push({ path: '/login' })
      });
    }

    return {
      state,
      onLoad,
      getProfile,
      onRefresh,
      activeNum,
      cpdList,
      onAlert,
      onClickLeft,
      onClickRight,
      beforeChange,
    };
  },
  methods: {},
};
</script>

<style lang="less">
.van-tab {
  position: relative;
  .pos-asb {
    position: absolute;
    right: 12px;
    top: 10px;
    width: 6px;
    height: 6px;
    border-radius: 10px;
    background-color: red;
  }
}
.van-nav-bar__title {
  h3 {
    line-height: 1;
    margin: 0;
    font-size: 16px;
  }
  p {
    line-height: 1;
    margin: 3px 0 0 0;
    font-size: 12px;
  }
}
.click-refuse {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 250px;
}
</style>
